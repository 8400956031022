import axios from 'axios'
import { Loading, MessageBox } from 'element-ui'
import store from '../store'
// docs: https://github.com/mzabriskie/axios
const instance = axios.create({
  baseURL: __APIROOT__ // `__APIROOT__` definded in `fbi/options.js=>data`
})
let loading
let loadingNum = 0

instance.interceptors.request.use(config =>{
  if(store.state.csrfToken){
    config.headers['csrf-token'] = store.state.csrfToken
  }
  let ignoreLoading = config.ignoreLoading
  if(config.ignoreLoading){
    // delete config.ignoreLoading
  }
  if(!ignoreLoading){
    loadingNum++
    loading = Loading.service({
      text: '正在执行操作，请稍候...',
      spinner: 'icon-loading',
      background: 'rgba(0, 0, 0, 0.4)',
      customClass: 'custom-loading',
      body: true,
      lock: false
    })
  }
  return config
},error => {
  MessageBox.alert('网络错误,请稍候重试！', '提示')
  return error
})
instance.interceptors.response.use(response =>{
  // if(response.headers['x-csrf-token']){
  //   store.commit('setCsrfToken', response.headers['x-csrf-token'])
  // }
  if(loading){
    loading.close()
  }
  if(!response.config.ignoreLoading){
    loadingNum--
    if(loading && loadingNum <= 0){
      loadingNum = 0
      loading.close()
    }
  }
  if(response.headers.api && !response.config.ignoreExpire){
    window.location.href = '/'
  }
  if(response.data.status === 'FAILED' && response.data.errorMessage && !response.config.ignoreError){
    MessageBox.alert(response.data.errorMessage, '提示')
  }
  return response.data
}, error => {
  if(loading){
    loadingNum = 0
    loading.close()
  }
  if(!error.response){
    MessageBox.alert('网络错误，请检查后重试！', '提示')
    return error
  }
  MessageBox.alert('服务器内部错误,请稍候重试！', '提示')
  return error
})
export default {
  ...instance,
  responseHandler (response) {
    return Promise.resolve(response.data)
  }
}
