import Vue from 'vue'
import Vuex from 'vuex'
import unifiedServerAdmin from '@/api/unifiedServerAdmin.js'
import customService from '@/api/custom.js'

Vue.use(Vuex)

const state = {
  isLogin: false,
  documentTitle: '',
  navDocIsShow: true,
  account: {}, //账号信息
  user: {}, //用户基本资源数据menu
  orgList: [], //组织机构列表
  enableRoleList: [], //可用角色列表
  modAccountInfo: {}, //编辑账号相关信息
  modRoleInfo: {}, //编辑角色相关信息
  accountManage: '', //当前账号管理标识（车厂、经销商）
  dealerList: [], //经销商列表
  allResource: [], //所有资源列表
  goSubFlag: null, //直接跳转子系统标识
  accountForbidden: false,

  csrfToken: undefined
}
const getters = {}
const mutations = {
  setCsrfToken(state, payload){
    state.csrfToken = payload
  },
  setNavDocIsShow (state, payload) {
    state.navDocIsShow = payload
  },
  setIsLogin (state) {
    state.isLogin = true
  },
  setGoSubFlag (state, payload) {
    state.goSubFlag = payload
  },
  setAccount (state, payload) {
    state.account = payload
  },
  setUserData (state, payload) {
    state.user = payload
  },
  setDocumentTitle (state, payload) {
    state.documentTitle = payload
  },
  setOrgList (state, payload) {
    state.orgList = payload
  },
  setEnableRoleList (state, payload) {
    state.enableRoleList = payload
  },
  setmodAccountInfo (state, payload) {
    state.modAccountInfo = payload
  },
  setAccountManage (state, payload) {
    state.accountManage = payload
  },
  setDealerList (state, payload) {
    state.dealerList = payload
  },
  setModRoleInfo (state, payload) {
    state.modRoleInfo = payload
  },
  setAllResource (state, payload) {
    // if(payload && payload.length){
    //   payload = payload.filter(item => item.id !== 'factoryData')
    // }
    state.allResource = payload
  },
  setAccountForbidden (state, payload) {
    state.accountForbidden = payload
  }
}
const actions = {
  getUserData ({commit}) {
    return new Promise((resolve, reject) => {
      customService.baseData().then(result => {
        if (result.status === 'SUCCEED') {
          commit('setUserData', result.baseData)
          commit('setAccount', result.userInfo)
        }
        resolve(result)
      })
    })
  },
  getOrgList({state, commit}, force){
    if(state.orgList.length && !force){
      return
    }
    unifiedServerAdmin.getOrgTree().then(result => {
      if (result.status === 'SUCCEED') {
        commit('setOrgList', result.data)
      }
    })
  },
  getEnableRoleList({state, commit}, force){
    if(state.enableRoleList.length && !force){
      return
    }
    let params = {query: {}}
    unifiedServerAdmin.getRoleListEnable(params).then(result => {
      if (result.status === 'SUCCEED') {
        commit('setEnableRoleList', result.data)
      }
    })
  },
  getDealerList ({state, commit}, force) {
    if(state.dealerList.length && !force){
      return
    }
    return new Promise((resolve, reject) => {
      unifiedServerAdmin.getOrgListOneLayer().then(result => {
        if (result.status === 'SUCCEED') {
          commit('setDealerList', result.data)
        }
        resolve(result)
      })
    })
  },
  getAllResource({state, commit}, force){
    if(state.allResource.length && !force){
      return
    }
    let params = {query: {}}
    unifiedServerAdmin.getAllResource(params).then(result => {
      if (result.status === 'SUCCEED') {
        commit('setAllResource', result.data)
      }
    })
  }
}

export default new Vuex.Store({
  strict: true,
  state,
  getters,
  mutations,
  actions
})
