import store from '@/store'
import unifiedServerAdmin from '@/api/unifiedServerAdmin.js'
import customService from '@/api/custom.js'

export default {
  // https://router.vuejs.org/api/#router-beforeeach`
  beforeEach: {
    checkAuth (to, from, next) {
      if (to.matched.some(m => m.meta.auth)) {
        if (store.state.isLogin) {
          if (to.meta.dispark || store.state.user.authority.indexOf(to.path) > -1) {
            next()
          } else {
            next(false)
          }
        } else {
          //存储子系统标识参数
          if (/^\/$/.test(to.path) && to.query.flag && Object.keys(to.query).length < 2) {
            window.localStorage.setItem('flag', to.query.flag)
          }
          store.dispatch('getUserData').then(res => {
            if (res.status === 'SUCCEED') {
              store.commit('setIsLogin')
              window.localStorage.removeItem('isVerify')
              window.localStorage.removeItem('name')
              window.localStorage.removeItem('mobile')
              if (res.baseData.goSubFlag) {
                store.commit('setGoSubFlag', res.baseData.goSubFlag)
              }
              next({path: to.path, replace: true, query: to.query})
            } else if (res.status === 'FAILED' && res.errorMessage === 'noAuthorized') {
              //参数code业务登录操作
              if (/^\/$/.test(to.path) && to.query.code && to.query.state && to.query.state === window.localStorage.getItem('state')) {
                unifiedServerAdmin.loginByBusinessCode({code: to.query.code}).then(result => {
                  if (result.status === 'SUCCEED') {
                    next({path: to.path, replace: true})
                  } else {
                    if (result.isVerify && result.userInfo) {
                      window.localStorage.setItem('isVerify', result.isVerify)
                      window.localStorage.setItem('name', result.userInfo.accountName || '')
                      window.localStorage.setItem('mobile', result.userInfo.mobile || '')
                      next({path: '/verify', replace: true})
                    } else {
                      if (result.errorCode === 'server-admin.0116') {
                        store.commit('setAccountForbidden', true)
                        next({path:'forbidden', replace: true})
                      } else {
                        next({path:'error', replace: true})
                      }
                    }
                  }
                })
              } else if (/^\/verify$/.test(to.path) && window.localStorage.getItem('isVerify')) {
                //访问验证码页面
                next()
              } else {
                customService.authorize().then(result => {
                  if (result.status === 'SUCCEED') {
                    let nowTime = new Date().getTime()
                    let saveVisitConfig = (time, count) => {
                      window.sessionStorage.setItem('visitConfig', JSON.stringify({time: time, count: count}))
                    }
                    //初始化访问参数
                    if (!window.sessionStorage.getItem('visitConfig')) { saveVisitConfig(nowTime, 0) }
                    //如果访问腾讯页面时间在limitTime以上,需要初始化访问参数
                    if (nowTime - JSON.parse(window.sessionStorage.getItem('visitConfig')).time > result.visit.limitTime) { saveVisitConfig(nowTime, 0) }
                    //重新获取最新的访问参数
                    let visitConfigObj = JSON.parse(window.sessionStorage.getItem('visitConfig'))
                    //防止重复调用腾讯服务
                    if (result.visit.switch && nowTime - visitConfigObj.time < result.visit.limitTime && visitConfigObj.count > result.visit.count) {
                      saveVisitConfig(nowTime, 0)
                      next({path:'error', replace: true})
                    } else {
                      saveVisitConfig(nowTime, ++visitConfigObj.count)
                      window.localStorage.setItem('state', result.state)
                      window.location.href = result.url
                    }
                  } else {
                    next({path:'error', replace: true})
                  }
                })
              }
            }
          })
        }
      } else {
        next()
      }
    },
    setDocumentTitle (to, from, next) {
      if (to.meta && to.meta.title) {
        store.commit('setDocumentTitle', to.meta.title)
      }
      next()
    }
  }
}
