import '@/utils/rem'
import '@/style/base.scss'
import '@/style/element-variables.scss'
import 'babel-polyfill'
import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import common from './components/common.js'  // 引入 common.js
import {  
  Row,
  Col,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Tree,
  Pagination,
  Button,
  Input,
  Icon,
  Message,
  MessageBox,
  Form,
  FormItem,
  Select,
  Option,
  Collapse,
  CollapseItem,
  DatePicker,
  Dialog,
  Alert,
  CheckboxGroup,
  Checkbox,
  Radio,
  RadioGroup,
  RadioButton,
  Badge,
  TimePicker,
  TimeSelect,
  Loading,
  Popover
} from 'element-ui'

Vue.config.productionTip = true
Vue.config.devtools = ENV === 'dev'

Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` 是组件的继承关系追踪
  console.warn(msg, vm, trace)
}

Vue.config.errorHandler = function (err, vm, info) {
  // handle error
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 只在 2.2.0+ 可用
  console.warn(err, vm, info)
}

Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Alert)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Tabs)
Vue.use(Badge)
Vue.use(TabPane)
Vue.use(TimePicker)
Vue.use(TimeSelect)
Vue.use(Tree)
Vue.use(Popover)

Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
Vue.prototype.$common = common

axios.head(__APIROOT__+'/getCsrfToken').then(res=>{
  if(res.headers['x-csrf-token']){
    store.commit('setCsrfToken', res.headers['x-csrf-token'])
  }
  new Vue({
    router,
    store,
    ...App
  }).$mount('#app')
}, err=>{
  new Vue({
    router,
    store,
    ...App
  }).$mount('#app')
})

