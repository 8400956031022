import ajax from '@/utils/ajax'

export default {
  baseData () {
    return ajax.post('/api/baseData', {}, {ignoreError: true, headers: {ignoreLoginRedirect: true}})
  },
  authorize () {
    return ajax.post('/api/login/authorize')
  },
  changePassword () {
    return ajax.post('/api/changePassword')
  }
}
