// src/common.js

export default {
  showAlert(message) {
    
  },
//手机号码
  maskPhoneNumber(phoneNumber) {
    if (phoneNumber.length < 4) {
      return phoneNumber;
    }
    const firstTwo = phoneNumber.slice(0, 2);
    const lastTwo = phoneNumber.slice(-2);
    const maskedSection = '*'.repeat(phoneNumber.length - 4);
    return `${firstTwo}${maskedSection}${lastTwo}`;
  },
  //邮箱
  maskEmail(email) {
    if(email){
      const [name, domain] = email.split('@');
    if (name.length <= 2) {
      return email;  // 如果名字部分长度小于等于2，则不进行遮蔽
    }
    const firstChar = name.charAt(0);
    const lastChar = name.charAt(name.length - 1);
    const maskedSection = '*'.repeat(name.length - 2);
    return `${firstChar}${maskedSection}${lastChar}@${domain}`;
    }else{
      return email;
    }
    
  },
//vin
  maskVin(vin) {
    if (vin.length < 10) {
      return vin;
    }
    const firstThree = vin.slice(0, 3);
    const lastSeven = vin.slice(-7);
    const maskedSection = '*'.repeat(vin.length - 10);
    return `${firstThree}${maskedSection}${lastSeven}`;
  }
  // 其他全局方法...
}
