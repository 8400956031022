import ajax from '@/utils/ajax'

export default {
  getRoleList (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/role/getRolePageCondition', body)
  },
  getRoleListEnable () {
    return ajax
      .post('/api/unifiedServerAdmin/admin/role/getRoleListEnable')
  },
  changeRoleStatus (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/role/changeRoleStatus', body)
  },
  deleteByRoleId (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/role/deleteByRoleId', body)
  },
  getOrgTree (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/organization/getOrgTree', body)
  },
  addOrg (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/organization/addOrg', body)
  },
  modifyOrg (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/organization/modifyOrg', body)
  },
  deleteOrg (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/organization/deleteOrg', body)
  },
  getOrgListOneLayer (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/getOrgListOneLayer', body)
  },
  searchAccount (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/searchAccount', body)
  },
  changeAccountStatus (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/changeAccountStatus', body)
  },
  deleteAccountById (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/deleteAccountById', body)
  },
  resetPassword (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/resetPassword', body)
  },
  addAccount (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/addAccount', body)
  },
  modifyAccount (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/modifyAccount', body)
  },
  getUrlBySubsystemCode (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/resource/getUrlBySubsystemCode', body)
  },
  loginByBusinessCode (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/provider/loginByBusinessCode', body)
  },
  getListByAcc (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/resource/getListByAcc', body)
  },
  addRole (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/role/addRole', body)
  },
  modifyRole (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/role/modifyRole', body)
  },
  loginByVerifyCode (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/provider/loginByVerifyCode', body)
  },
  getAllResource (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/resource/getAllResource', body)
  },
  modifyAccountByToken (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/modifyAccountByToken', body)
  },
  logout () {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/logout')
  },
  getInfoByToken (body) {
    return ajax
      .post('/api/unifiedServerAdmin/admin/account/getInfoByToken')
  },
}
