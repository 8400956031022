import { render, staticRenderFns } from "./App.vue?vue&type=template&id=0ab5185d"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&lang=scss"


/* normalize component */
import normalizer from "!C:\\Users\\86185\\.fbi\\fbi-project-fly-vue\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports