import Vue from 'vue'
import Router from 'vue-router'
import hooks from './hooks'
import store from '@/store'
import unifiedServerAdmin from '@/api/unifiedServerAdmin.js'

const Main = () => import('@/views/main/Main.vue' /* webpackChunkName: "chunk-home" */)
const Verify = () => import('@/views/verify/Verify.vue')
const Error = () => import('@/views/error/Error.vue')
const SubSystemList = () => import('@/views/main/children/SubSystemList.vue')
const AccountManage = () => import('@/views/main/children/AccountManage.vue')
const FOrganManage = () => import('@/views/main/children/FOrganManage.vue')
const DOrganManage = () => import('@/views/main/children/DOrganManage.vue')
const RoleManage = () => import('@/views/main/children/RoleManage.vue')
const AccountAdd = () => import('@/views/main/children/AccountAdd.vue')
const AccountMod = () => import('@/views/main/children/AccountMod.vue')
const HandleRole = () => import('@/views/main/children/HandleRole.vue')
const Welcome = () => import('@/views/main/children/Welcome.vue')
// import Home from '@/views/Home.vue'09-0

Vue.use(Router)

const routerInstance = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      meta: {
        title: '账号管理系统',
        auth: true,
        dispark: true
      },
      beforeEnter: (to, from, next) => {
        let flag = to.query.flag || window.localStorage.getItem('flag') || store.state.goSubFlag
        if (flag) {
          window.localStorage.removeItem('flag')
          let params = {
            query: {},
            body: {
              systemCode: flag,
              iss: flag
            }
          }
          unifiedServerAdmin.getUrlBySubsystemCode(params).then(result => {
            if (result.status === 'SUCCEED') {
              window.location.href = result.data
            } else {
              next('/main')
            }
          })
        } else {
          next('/main')
        }
      }
    },
    {
      path: '/main',
      component: Main,
      children: [
        {
          path: '',
          component: Welcome,
          meta: {
            title: '账号管理系统',
            auth: true,
            dispark: true
          },
          beforeEnter: (to, from, next) => {
            store.commit('setNavDocIsShow', false)
            next()
          }
        },
        {
          path: 'subSystemList',
          component: SubSystemList,
          meta: {
            title: '子系统列表',
            auth: true
          }
        },
        {
          path: 'accountManage/factory',
          component: AccountManage,
          props: {
            manage: 'croot'
          },
          meta: {
            title: '车厂账号管理',
            auth: true
          }
        },
        {
          path: 'accountManage/dealer',
          component: AccountManage,
          props: {
            manage: 'droot'
          },
          meta: {
            title: '经销商账号管理',
            auth: true
          }
        },
        {
          path: 'accountManage/accountAdd',
          component: AccountAdd,
          meta: {
            title: '新建账号',
            auth: true
          },
          beforeEnter: (to, from, next) => {
            if (to.query.c === 'croot' || to.query.c === 'droot') {
              next()
            } else {
              next('/')
            }
          } 
        },
        {
          path: 'accountManage/accountMod',
          component: AccountMod,
          meta: {
            title: '编辑账号',
            auth: true
          },
          beforeEnter: (to, from, next) => {
            if (store.state.modAccountInfo.username) {
              next()
            } else {
              if (to.query.c === 'droot') {
                next('/main/accountManage/dealer')
              } else if (to.query.c === 'croot') {
                next('/main/accountManage/factory')
              } else {
                next('/')
              }
            }
          }
        },
        {
          path: 'organManage/factory',
          component: FOrganManage,
          meta: {
            title: '车厂机构管理',
            auth: true
          }
        },
        {
          path: 'organManage/dealer',
          component: DOrganManage,
          meta: {
            title: '经销商机构管理',
            auth: true
          }
        },
        {
          path: 'roleManage',
          component: RoleManage,
          meta: {
            title: '角色权限管理',
            auth: true
          }
        },
        {
          path: 'roleManage/handle',
          component: HandleRole,
          meta: {
            auth: true
          },
          beforeEnter: (to, from, next) => {
            let title = null
            if (to.query.t === 'add') {
              title = '新建角色'
              next()
            } else if (to.query.t === 'mod') {
              if (store.state.modRoleInfo.id) {
                title = '编辑角色'
                next()
              } else {
                title = '角色权限管理'
                next('/main/roleManage')
              }
            } else {
              next('/')
            }
            store.commit('setDocumentTitle', title)
          }
        }
      ],
      meta: {
        title: '账号管理系统',
        auth: true
      }
    },
    {
      path: '/verify',
      component: Verify,
      meta: {
        auth: true,
        dispark: true
      },
      beforeEnter: (to, from, next) => {
        if (store.state.isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/error',
      component: Error
    },
    {
      path: '/forbidden',
      component: Error,
      props: {
        err: '用户已被禁用，请联系管理员'
      },
      beforeEnter: (to, from, next) => {
        if (!store.state.accountForbidden) {
          next('/')
        } else {
          next()
        }
      }
    }
  ]
})

const beforeEachKeys = Object.keys(hooks.beforeEach)

if (beforeEachKeys.length > 0) {
  beforeEachKeys.map(hook => {
    routerInstance.beforeEach(hooks.beforeEach[hook])
  })
}

export default routerInstance
